import TYPES from '@/types';
import { v4 } from 'uuid';
import {
  bankAccountFormat, requiredRule, minLengthRule, exactLengthRule,
} from '@/vue-app/utils/form-rules';

// Infrastructure
import Functions from '@/modules/shared/infrastructure/utils/functions';

// Application
import SearchInstitutionsQuery
  from '@/modules/onboarding/institutions/application/queries/search-institutions-query';
import GetCountriesQueryService
  from '@/modules/onboarding/catalogs/country/application/queries/get-countries-query-service';
import GetAllCurrenciesQuery
  from '@/modules/onboarding/catalogs/currency/application/queries/get-all-currencies-query';
import GetCustomerBase64DocumentQuery
  from '@/modules/onboarding/customer-document/application/queries/get-customer-base64-document-query';
import { GetOnboardingStepQuery } from '@/modules/onboarding/status/application/queries';
import { UploadSecondBankStatementFileCommand }
  from '@/modules/onboarding/documents/application/commands/';
import SearchDocumentsQuery
  from '@/modules/onboarding/documents/application/queries/search-documents-query';
import MimeTypeFromBase64
  from '@/modules/onboarding/file/application/services/mime-type-from-base64';
import Base64DataUriToFileConverter
  from '@/modules/onboarding/file/application/services/base64-data-uri-to-file-converter';

// Domain
import { StepEntity } from '@/modules/onboarding/status/domain/entities';
import { CountryEntity } from '@/modules/onboarding/catalogs/country/domain/entities/country-entity';
import { CurrencyEntity } from '@/modules/onboarding/catalogs/currency/domain/entities/currency-entity';
import { DocumentEntity } from '@/modules/onboarding/documents/domain/entities/document-entity';
import { SearchInstitutionDto } from '@/modules/onboarding/institutions/domain/dto/search-institution-dto';
import { InstitutionPaginatedEntity } from '@/modules/onboarding/institutions/domain/entities/institution-paginated-entity';
import { InstitutionEntity } from '@/modules/onboarding/institutions/domain/entities/institution-entity';
import Inject from '@/modules/shared/domain/dependency_injection/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

type ExtraBankAccount = {
  type_of_bank_account: string;
  siap_institution_id: number | null;
  bank_account: string;
  financial_institution: string;
  bank_statement_file_id: null | string;
  confirm_new_account: boolean;
  foreign_account: {
    beneficiary: string;
    siap_institution_id: number | null;
    siap_institution_name: string;
    id_cat_pais: string;
    account_or_iban: string;
    aba: string;
    swift: string;
    for_further_credit_to: string;
    currency_id: number;
    currency_description: string;
  };
}

export default class AddBankAccountComponentViewModel {
  @Inject(TYPES.SEARCH_SIAP_INSTITUTIONS_QUERY)
  private readonly search_siap_institutions_query!: SearchInstitutionsQuery;

  @Inject(TYPES.GET_ONBOARDING_STEP_QUERY)
  private readonly get_on_boarding_step_query!: GetOnboardingStepQuery;

  @Inject(TYPES.GET_COUNTRIES_QUERY_SERVICE)
  readonly get_countries_query!: GetCountriesQueryService;

  @Inject(TYPES.GET_ALL_CURRENCIES_QUERY)
  readonly get_all_currencies_query!: GetAllCurrenciesQuery;

  @Inject(TYPES.ON_BOARDING_UPLOAD_SECOND_BANK_STATEMENT_FILE_COMMAND)
  private readonly upload_second_bank_statement_file_command!: UploadSecondBankStatementFileCommand

  @Inject(TYPES.GET_CUSTOMER_BASE64_DOCUMENT_QUERY)
  readonly get_customer_base64_document_query!: GetCustomerBase64DocumentQuery;

  @Inject(TYPES.SEARCH_DOCUMENTS_QUERY)
  private readonly search_documents_query!: SearchDocumentsQuery;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.UTIL_FUNCTIONS)
  private readonly functions!: Functions;

  is_file_loading = false;

  is_form_valid = false;

  current_step: StepEntity = {
    current_step: '',
    id: '',
    payload: {},
  };

  type_of_bank_account = [
    {
      name: 'Nacional',
      value: 'national',
    },
    {
      name: 'Extranjera',
      value: 'foreign',
    },
  ]

  bank_statement_file: null | File = null;

  add_other_bank_account_inputs: ExtraBankAccount = {
    type_of_bank_account: 'national',
    siap_institution_id: null,
    bank_account: '',
    financial_institution: '',
    bank_statement_file_id: null,
    confirm_new_account: false,
    foreign_account: {
      beneficiary: '',
      siap_institution_id: null,
      siap_institution_name: '',
      id_cat_pais: '2',
      account_or_iban: '',
      aba: '',
      swift: '',
      for_further_credit_to: '',
      currency_id: 0,
      currency_description: '',
    },
  }

  national_institution_paginated_entity: InstitutionPaginatedEntity = {
    items: [],
    pagination: {
      page: 1,
      next_page: 1,
      previous_page: 1,
      page_size: 10,
      total_pages: 1,
      total_items: 0,
    },
  }

  selected_national_institution_entity: InstitutionEntity = {
    institution_id: 0,
    institution_type_id: 0,
    risk_id: 0,
    description: '',
    quality: '',
    company_name: '',
    day_type: '',
    national: '',
    status: '',
    cta_indeval: null,
    nemonico: null,
    bank_code: null,
  }

  foreign_institution_paginated_entity: InstitutionPaginatedEntity = {
    items: [],
    pagination: {
      page: 1,
      next_page: 1,
      previous_page: 1,
      page_size: 10,
      total_pages: 1,
      total_items: 0,
    },
  }

  selected_foreign_institution_entity: InstitutionEntity = {
    institution_id: 0,
    institution_type_id: 0,
    risk_id: 0,
    description: '',
    quality: '',
    company_name: '',
    day_type: '',
    national: '',
    status: '',
    cta_indeval: null,
    nemonico: null,
    bank_code: null,
  }

  search_national_institution_dto: SearchInstitutionDto = {
    query_spec: [
      { field: 'NACIONAL', op: 'eq', value: 'N' },
      { field: 'ESTATUS', op: 'eq', value: 'A' },
      { field: 'DESCRIPCION_INSTITUCION', op: 'ilike', value: '' },
    ],
    pagination_spec: { page: 1, page_size: 10 },
    sort_spec: [
      { field: 'DESCRIPCION_INSTITUCION', direction: 'asc', nulls: 'first' },
    ],
  }

  search_foreign_institution_dto: SearchInstitutionDto = {
    query_spec: [
      { field: 'NACIONAL', op: 'eq', value: 'E' },
      { field: 'ESTATUS', op: 'eq', value: 'A' },
      { field: 'DESCRIPCION_INSTITUCION', op: 'ilike', value: '' },
    ],
    pagination_spec: { page: 1, page_size: 10 },
    sort_spec: [
      { field: 'DESCRIPCION_INSTITUCION', direction: 'asc', nulls: 'first' },
    ],
  }

  current_search_national_bank = '';

  search_national_bank_prev_value = '';

  is_national_bank_searcher_loading = false;

  current_search_foreign_bank = '';

  search_foreign_bank_prev_value = '';

  is_foreign_bank_searcher_loading = false;

  timer?: NodeJS.Timer;

  countries: Array<CountryEntity> = []

  is_countries_loading = false;

  is_currencies_loading = false;

  foreign_currencies: Array<CurrencyEntity> = []

  selected_foreign_currency: CurrencyEntity = {
    currency_id: 0,
    description: '',
    foreign: 0,
    emisora_id: null,
    currency_abbreviations: null,
    currency_letter: null,
  }

  rules = {
    required: [requiredRule],
    currency: [(value: CurrencyEntity) => !!value.currency_id],
    bank_account: [requiredRule, bankAccountFormat],
    foreign_bank_account: [requiredRule, (value: string) => minLengthRule(8, value)],
    iban_number: [requiredRule, (value: string) => minLengthRule(18, value)],
    aba_number: [requiredRule, (value: string) => exactLengthRule(9, value)],
  };

  inputs_config = {
    bank_statement: {
      accept: 'image/jpg,image/jpeg,image/png',
      size_limit: 1000 * 1024 * 4,
    },
  };

  show_preview = false;

  documents: Array<DocumentEntity> = [];

  new_bank_statement_uuid_file_id = v4();

  get valid_bank_data() {
    if (this.is_national_account) {
      return (
        this.add_other_bank_account_inputs.siap_institution_id
        && this.add_other_bank_account_inputs.bank_account
          .replaceAll(' ', '')
          .match(/[0-9]{18}/)
      );
    }
    return this.add_other_bank_account_inputs.foreign_account.siap_institution_id;
  }

  get is_checkbox_disabled() {
    return !this.is_form_valid || this.is_file_loading || !this.valid_bank_data;
  }

  get is_national_account() {
    return this.add_other_bank_account_inputs.type_of_bank_account === 'national';
  }

  get is_foreign_account_eu() {
    return this.add_other_bank_account_inputs.foreign_account.id_cat_pais === '2';
  }

  get foreign_account_text() {
    return (this.is_foreign_account_eu) ? '' : '/IBAN';
  }

  get account_rule() {
    return (this.is_foreign_account_eu) ? this.rules.foreign_bank_account : this.rules.iban_number;
  }

  get the_search_national_bank_value_changed() {
    return this.current_search_national_bank !== this.search_national_bank_prev_value;
  }

  get the_search_foreign_bank_value_changed() {
    return this.current_search_foreign_bank !== this.search_foreign_bank_prev_value;
  }

  get there_are_more_pages_search_national_bank_institution() {
    return this.search_national_institution_dto.pagination_spec
      .page < this.national_institution_paginated_entity.pagination.total_pages;
  }

  get there_are_more_pages_search_foreign_bank_institution() {
    return this.search_foreign_institution_dto.pagination_spec
      .page < this.foreign_institution_paginated_entity.pagination.total_pages;
  }

  getSearchBankText = (item: InstitutionEntity) => `${item.description}`;

  searchNationalBankInstitutions = async (clean_items: boolean) => {
    try {
      if (this.current_search_national_bank.length >= 2) {
        this.is_national_bank_searcher_loading = true;
        if (clean_items) {
          this.national_institution_paginated_entity = {
            items: [],
            pagination: {
              next_page: 0,
              page: 0,
              page_size: 0,
              previous_page: 0,
              total_items: 0,
              total_pages: 0,
            },
          };
          this.search_national_institution_dto.pagination_spec.page = 1;
        }
        this.search_national_bank_prev_value = this.current_search_national_bank;
        const index = this.search_national_institution_dto.query_spec.findIndex((item) => item.field === 'DESCRIPCION_INSTITUCION');
        this.search_national_institution_dto.query_spec[index].value = `%${this.current_search_national_bank}%`;
        const items = await this.search_siap_institutions_query
          .execute(this.search_national_institution_dto);
        this.national_institution_paginated_entity = {
          items: [...this.national_institution_paginated_entity.items, ...items.items],
          pagination: { ...items.pagination },
        };
        this.is_national_bank_searcher_loading = false;
      }
    } catch {
      this.message_notifier.showErrorNotification('Ocurrió un error al obtener el catálogo de bancos nacionales');
    }
  }

  lazyLoadSearchNationalBankInstitutions = async (entries: Array<any>) => {
    if (entries[0].intersectionRatio
      && this.there_are_more_pages_search_national_bank_institution
      && !this.the_search_national_bank_value_changed) {
      this.search_national_institution_dto.pagination_spec
        .page = this.national_institution_paginated_entity.pagination.next_page;
      await this.searchNationalBankInstitutions(false);
    }
  }

  delaySearchBankInstitutions = (is_national_bank: boolean) => {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }
    this.timer = setTimeout(async () => {
      if (is_national_bank
        && this.current_search_national_bank
        && this.current_search_national_bank.length >= 2) {
        await this.searchNationalBankInstitutions(true);
      }
      if (!is_national_bank
        && this.current_search_foreign_bank
        && this.current_search_foreign_bank.length >= 2) {
        await this.searchForeignBankInstitutions(true);
      }
    }, 1500);
  }

  searchForeignBankInstitutions = async (clean_items: boolean) => {
    try {
      if (this.current_search_foreign_bank.length >= 2) {
        this.is_foreign_bank_searcher_loading = true;
        if (clean_items) {
          this.foreign_institution_paginated_entity = {
            items: [],
            pagination: {
              next_page: 0,
              page: 0,
              page_size: 0,
              previous_page: 0,
              total_items: 0,
              total_pages: 0,
            },
          };
          this.search_foreign_institution_dto.pagination_spec.page = 1;
        }
        this.search_foreign_bank_prev_value = this.current_search_foreign_bank;
        const index = this.search_foreign_institution_dto.query_spec.findIndex((item) => item.field === 'DESCRIPCION_INSTITUCION');
        this.search_foreign_institution_dto.query_spec[index].value = `%${this.current_search_foreign_bank}%`;
        const items = await this.search_siap_institutions_query
          .execute(this.search_foreign_institution_dto);
        this.foreign_institution_paginated_entity = {
          items: [...this.foreign_institution_paginated_entity.items, ...items.items],
          pagination: { ...items.pagination },
        };
        this.is_foreign_bank_searcher_loading = false;
      }
    } catch {
      this.message_notifier.showErrorNotification('Ocurrió un error al obtener el catálogo de bancos extranjeros');
    }
  }

  lazyLoadSearchForeignBankInstitutions = async (entries: Array<any>) => {
    if (entries[0].intersectionRatio
      && this.there_are_more_pages_search_foreign_bank_institution
      && !this.the_search_foreign_bank_value_changed) {
      this.search_foreign_institution_dto.pagination_spec
        .page = this.foreign_institution_paginated_entity.pagination.next_page;
      await this.searchForeignBankInstitutions(false);
    }
  }

  setSelectedBankToSyncVariable = (is_national_bank: boolean) => {
    if (is_national_bank) {
      this.add_other_bank_account_inputs
        .siap_institution_id = this.selected_national_institution_entity.institution_id;
      this.add_other_bank_account_inputs
        .financial_institution = this.selected_national_institution_entity.description;
    } else {
      this.add_other_bank_account_inputs.foreign_account.siap_institution_id = this
        .selected_foreign_institution_entity.institution_id;
      this.add_other_bank_account_inputs.foreign_account.siap_institution_name = this
        .selected_foreign_institution_entity.description;
    }
  }

  setSelectedCurrencyToSyncVariable = () => {
    this.add_other_bank_account_inputs.foreign_account.currency_id = this
      .selected_foreign_currency.currency_id;
    this.add_other_bank_account_inputs.foreign_account.currency_description = this
      .selected_foreign_currency.description;
  }

  selectFile = async ({ target }: any) => {
    const selected_file = target.files[0];
    if (selected_file) {
      this.is_file_loading = true;
      const valid_file_size = (
        selected_file.size <= this.inputs_config.bank_statement.size_limit
      );

      if (valid_file_size) {
        try {
          if (!this.add_other_bank_account_inputs.bank_statement_file_id) {
            this.add_other_bank_account_inputs
              .bank_statement_file_id = this.new_bank_statement_uuid_file_id;
          }
          const file_data = await this.functions.convert_file_to_base_64(selected_file);
          const create_file_dto = {
            id: this.add_other_bank_account_inputs.bank_statement_file_id,
            name: selected_file.name,
            mime_type: selected_file.type,
            file_data: file_data as string,
          };

          await this.upload_second_bank_statement_file_command.execute(create_file_dto);
          this.bank_statement_file = selected_file;
          this.show_preview = true;
        } catch {
          this.show_preview = false;
          this.bank_statement_file = null;
          this.is_file_loading = false;
        }
      } else {
        this.message_notifier.showErrorNotification('El estado de cuenta no puede pesar más de 4 Mb');
      }
      this.is_file_loading = false;
    }
  };

  downloadCustomerDocumentPreview = async (customer_document_id: string, file_name: string) => {
    try {
      const base64 = await this.get_customer_base64_document_query.execute(customer_document_id);
      const mime_type = MimeTypeFromBase64.getFileMimeType(base64);
      const filePreview = await Base64DataUriToFileConverter.convert(base64, mime_type, file_name);
      this.show_preview = true;
      this.is_file_loading = false;
      return filePreview;
    } catch {
      this.show_preview = false;
      this.is_file_loading = false;
      this.message_notifier.showErrorNotification('Ocurrió un error al descargar la previsualización del documento');
      return null;
    }
  }

  setDocumentData = async () => {
    if (!this.bank_statement_file) {
      this.is_file_loading = true;
      const second_bank_statement = this.documents.find(
        (item) => item.document_type.name === 'second_bank_statement',
      );

      if (second_bank_statement) {
        const file_name = second_bank_statement.file.name;
        this.bank_statement_file = await this.downloadCustomerDocumentPreview(
          second_bank_statement.customer_document_id, file_name,
        );
      }
    }
  }

  getAllCustomerDocuments = async () => {
    try {
      this.documents = await this.search_documents_query.execute();
    } catch {
      this.message_notifier.showErrorNotification('Ocurrió un error al obtener los documentos del usuario');
    }
  };

  loadCountries = async () => {
    try {
      this.is_countries_loading = true;
      const countries = await this.get_countries_query.execute();
      this.countries = countries.filter((country) => country.id !== '1');
      this.is_countries_loading = false;
    } catch {
      this.message_notifier.showErrorNotification('Ocurrió un error al cargar los países');
    }
  }

  loadForeignAccountCatalogs = async () => {
    if (!this.is_national_account) {
      await this.loadCurrencies();
      await this.loadCountries();
    }
  }

  loadCurrencies = async () => {
    try {
      this.is_currencies_loading = true;
      const currencies = await this.get_all_currencies_query.execute();
      this.foreign_currencies = currencies.filter((currency) => currency.foreign === 1);
      this.is_currencies_loading = false;
    } catch {
      this.message_notifier.showErrorNotification('Ocurrió un error al cargar los tipos de divisa');
    }
  }

  initialize = async (add_other_bank_account_inputs: ExtraBankAccount) => {
    this.add_other_bank_account_inputs = add_other_bank_account_inputs;
    await this.loadForeignAccountCatalogs();
    if (this.add_other_bank_account_inputs.bank_statement_file_id) {
      await this.getAllCustomerDocuments();
      await this.setDocumentData();
    }
    if (this.add_other_bank_account_inputs.siap_institution_id) {
      this.current_search_national_bank = this.add_other_bank_account_inputs.financial_institution;
      await this.searchNationalBankInstitutions(true);
      this.selected_national_institution_entity = {
        ...this.national_institution_paginated_entity.items[0],
      };
    }
    if (this.add_other_bank_account_inputs.foreign_account.siap_institution_id) {
      this.current_search_foreign_bank = this.add_other_bank_account_inputs
        .foreign_account.siap_institution_name;
      await this.searchForeignBankInstitutions(true);
      this.selected_foreign_institution_entity = {
        ...this.foreign_institution_paginated_entity.items[0],
      };
    }
    if (this.add_other_bank_account_inputs.foreign_account.currency_id) {
      const selected_currency = this.foreign_currencies.find((currency) => currency
        .currency_id === this.add_other_bank_account_inputs.foreign_account.currency_id);
      if (selected_currency) this.selected_foreign_currency = { ...selected_currency };
    }
  }
}
