





















































































































































































































































































































import { Component, PropSync, Vue } from 'vue-property-decorator';
import AddBankAccountComponentViewModel
  from '@/vue-app/view-models/components/on-boarding/bank-information/add-bank-account-component-view-model';
import PreviewDocument from '@/vue-app/components/documents/preview-document.vue';

type ExtraBankAccount = {
  type_of_bank_account: string;
  siap_institution_id: null | number;
  bank_account: string;
  financial_institution: string;
  bank_statement_file_id: null | string;
  confirm_new_account: boolean;
  foreign_account: {
    beneficiary: string;
    siap_institution_id: number | null;
    siap_institution_name: string;
    id_cat_pais: string;
    account_or_iban: string;
    aba: string;
    swift: string;
    for_further_credit_to: string;
    currency_id: number;
    currency_description: string;
  };
}

@Component({
  name: 'AddBankAccountComponent',
  components: { PreviewDocument },
})
export default class AddBankAccountComponent extends Vue {
  @PropSync('addOtherBankAccountInputs')
  synced_add_other_bank_account_inputs!: ExtraBankAccount;

  add_bank_account_component = Vue.observable(new AddBankAccountComponentViewModel());

  async mounted() {
    await this.add_bank_account_component.initialize(this.synced_add_other_bank_account_inputs);
  }
}
